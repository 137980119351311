// components/Table/SmartContractTable.js
import React, {useEffect, useState} from 'react';
import {digitFormat, moneyFormat} from "../utils";

const HolderInfoTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/usdi_holders_info_table');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    console.log(data);
    return (
        <div className="table-responsive noto">
            <table className="table table-striped table-bordered mt-3">
                <thead className="thead-dark">
                <tr>
                    <th>address</th>
                    <th>current block number</th>
                    <th>last block number</th>
                    <th>status</th>
                    <th>cum dws</th>
                    <th>balance of usdi</th>

                    <th>bnp</th>
                    <th>dws</th>
                    <th>new cum dws</th>
                    <th>cum dws weight percent</th>
                    <th>user undistributed usdi</th>
                    <th>user unique id</th>

                    <th>cum distribued usdi</th>
                    <th>new cum dws after distribution</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.address}</td>
                        <td>{digitFormat(row.current_block_number)}</td>
                        <td>{digitFormat(row.last_block_number)}</td>
                        <td>{row.status}</td>
                        <td>{digitFormat(row.cum_dws)}</td>
                        <td>{moneyFormat(row.balance_of_usdi)}</td>

                        <td>{digitFormat(row.bnp)}</td>
                        <td>{digitFormat(row.dws)}</td>
                        <td>{digitFormat(row.new_cum_dws)}</td>
                        <td>{moneyFormat(row.cum_dws_weight_percent)}</td>
                        <td>{moneyFormat(row.user_undistributed_usdi)}</td>
                        <td>{digitFormat(row.user_unique_id)}</td>

                        <td>{moneyFormat(row.cum_distributed_usdi)}</td>
                        <td>{moneyFormat(row.new_cum_dws_after_distribution)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HolderInfoTable;
