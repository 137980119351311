// components/Table/SmartContractTable.js
import React, { useEffect, useState } from 'react';
import {digitFormat, moneyFormat} from "../utils";

const OverallUSDIPayout = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/overall_usdi_payout');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  console.log(data);
  return (
    <div className="table-responsive noto">
      <table className="table table-striped table-bordered mt-3">
        <thead className="thead-dark">
          <tr>
            <th>available_distribution_value</th>
            <th>block_no</th>
            <th>distribution_amount_fees</th>
            <th>distribution_id</th>
            <th>distribution_value</th>
            <th>final_distribution_amount_after_management_fees</th>
            <th>total_assets_value</th>
            <th>usdi_in_circulation</th>
            <th>usdi_locked</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{moneyFormat(row.available_distribution_value)}</td>
              <td>{digitFormat(row.block_no)}</td>
              <td>{moneyFormat(row.distribution_amount_fees)}</td>
              <td>{digitFormat(row.distribution_id)}</td>
              <td>{moneyFormat(row.distribution_value)}</td>
              <td>{moneyFormat(row.final_distribution_amount_after_management_fees)}</td>
              <td>{moneyFormat(row.total_assets_value)}</td>
              <td>{digitFormat(row.usdi_in_circulation)}</td>
              <td>{digitFormat(row.usdi_locked)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OverallUSDIPayout;
